import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Watchlist from '../components/dashboard/Watchlist';
import { useTheme } from '../contexts/ThemeContext';
import { StarIcon, CurrencyDollarIcon, ChartBarIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { useWatchlist } from '../contexts/WatchlistContext';
import SearchBar from '../components/common/SearchBar';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon, CalendarIcon, NewspaperIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Tab } from '@headlessui/react';
import { useStockDetailLogic } from '../hooks/useStockDetailLogic';
import Breadcrumb from '../components/layout/Breadcrumb';
import AnalystCrew from '../components/shared/AnalystCrew';
import { useHeaderHeight } from '../hooks/useHeaderHeight';
import { ExclamationTriangleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { stocksApi } from '../services/api';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const StockNotFound = ({ id }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] text-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full"
      >
        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8">
          <ExclamationTriangleIcon className="h-16 w-16 text-yellow-500 mx-auto mb-6" />
          <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">Stock Not Found</h1>
          <p className="text-lg mb-8 text-gray-600 dark:text-gray-400">
            We couldn't find any stock with the symbol "{id}".
          </p>
          <Link
            to="/"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Go Home
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

const StockDetailPage = () => {
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const { watchlist, lastUpdatedType, addToWatchlist, removeFromWatchlist } = useWatchlist();
  const headerHeight = useHeaderHeight();
  const [stockItem, setStockItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStockDetail = async () => {
      try {
        const data = await stocksApi.getStockDetail(id);
        setStockItem(data);
        setIsLoading(false);
      } catch (error) {
        setError(`Failed to fetch stock details for ${id}. Please try again later.`);
        setIsLoading(false);
      }
    };

    fetchStockDetail();
  }, [id]);

  const {
    isInWatchlist,
    activeAnalyst,
    analystReport,
    reportRef,
    tooltipContent,
    isAnalyzing,
    showTooltip,
    setHomePageTab,
    handleSearch,
    handleAnalystSelect,
    handleShare,
    handleMouseEnter,
    handleMouseLeave,
  } = useStockDetailLogic(id);

  const handleWatchlistToggle = useCallback(() => {
    if (isInWatchlist) {
      removeFromWatchlist(stockItem, 'stock');
    } else {
      addToWatchlist(stockItem, 'stock');
    }
  }, [isInWatchlist, addToWatchlist, removeFromWatchlist, stockItem]);

  const handleSetHomePageTab = (tab) => {
    navigate('/', { state: { activeTab: tab } });
  };

  if (isLoading) {
    return (
      <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
        <div className="flex flex-col items-center justify-center min-h-[60vh]">
          <ArrowPathIcon className="w-20 h-20 text-blue-500 animate-spin mb-4" />
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
            Loading Stock Details
          </h2>
          <p className="text-gray-500 dark:text-gray-400">
            Please wait while we fetch the latest data for {id}...
          </p>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
        <div>{error}</div>
      </Layout>
    );
  }

  if (!stockItem) {
    return (
      <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
        <StockNotFound id={id} />
      </Layout>
    );
  }

  return (
    <Layout 
      searchBar={<SearchBar onSearch={handleSearch} />} 
      stockItem={stockItem}
      breadcrumb={
        <Breadcrumb 
          stockItem={stockItem} 
          setHomePageTab={setHomePageTab}
        />
      }
    >
      <div className={`flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-2 ${isDarkMode ? 'dark' : ''}`}>
        <div className="lg:w-3/4">
          {/* Stock details section */}
          <article className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md overflow-hidden mb-6">
            <div className="p-6 relative">
              {/* Action buttons */}
              <div className="absolute top-2 right-2 flex"> {/* Removed space-x-1 */}
                {/* Watchlist toggle button */}
                <button 
                  onClick={handleWatchlistToggle}
                  className="p-1 text-gray-600 dark:text-gray-400 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors duration-200"
                  aria-label={isInWatchlist ? "Remove from watchlist" : "Add to watchlist"}
                >
                  {isInWatchlist ? (
                    <StarIconSolid className="h-5 w-5 text-yellow-500" />
                  ) : (
                    <StarIcon className="h-5 w-5" />
                  )}
                </button>
                {/* Share button */}
                <button 
                  onClick={handleShare}
                  className="p-1 text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-200"
                  aria-label="Share"
                >
                  <ArrowUpOnSquareIcon className="h-5 w-5" />
                </button>
              </div>

              <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">{stockItem.symbol} - {stockItem.name}</h1>
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-400 mb-6">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <CurrencyDollarIcon className="h-5 w-5 mr-2" />
                    <span>${stockItem.price.toFixed(2)}</span>
                  </div>
                  <div className="flex items-center">
                    {stockItem.change >= 0 ? (
                      <ArrowTrendingUpIcon className="h-5 w-5 mr-2 text-green-500" />
                    ) : (
                      <ArrowTrendingDownIcon className="h-5 w-5 mr-2 text-red-500" />
                    )}
                    <span className={stockItem.change >= 0 ? 'text-green-500' : 'text-red-500'}>
                      {stockItem.change >= 0 ? '+' : ''}{stockItem.change}%
                    </span>
                  </div>
                </div>
                <div className="flex items-center ml-4">
                  <CalendarIcon className="h-5 w-5 mr-2" />
                  <span>{new Date().toLocaleDateString()}</span>
                </div>
                <div className="flex items-center ml-4">
                  <NewspaperIcon className="h-5 w-5 mr-2" />
                  <span>{stockItem.sector}</span>
                </div>
              </div>
              <div className="prose dark:prose-invert max-w-none mb-6">
                <p className="text-lg text-gray-800 dark:text-gray-200">{stockItem.description}</p>
              </div>

              {/* Tabs for Key Statistics, Recent News, and Financial Data */}
              <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 dark:bg-gray-700 p-1">
                  <Tab
                    className={({ selected }) =>
                      `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all duration-200 flex items-center justify-center
                      ${
                        selected
                          ? 'bg-white dark:bg-gray-600 text-blue-600 dark:text-blue-300 shadow'
                          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 hover:text-gray-800 dark:hover:text-gray-100'
                      }`
                    }
                  >
                    <ChartBarIcon className="w-5 h-5 mr-2" />
                    Key Statistics
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all duration-200 flex items-center justify-center
                      ${
                        selected
                          ? 'bg-white dark:bg-gray-600 text-blue-600 dark:text-blue-300 shadow'
                          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 hover:text-gray-800 dark:hover:text-gray-100'
                      }`
                    }
                  >
                    <NewspaperIcon className="w-5 h-5 mr-2" />
                    Recent News
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      `w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all duration-200 flex items-center justify-center
                      ${
                        selected
                          ? 'bg-white dark:bg-gray-600 text-blue-600 dark:text-blue-300 shadow'
                          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 hover:text-gray-800 dark:hover:text-gray-100'
                      }`
                    }
                  >
                    <CurrencyDollarIcon className="w-5 h-5 mr-2" />
                    Financial Data
                  </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                  <Tab.Panel className={`rounded-xl bg-white p-3 dark:bg-gray-800`}>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {[
                        { label: 'Market Cap', value: stockItem.marketCap ? `$${stockItem.marketCap.toLocaleString()}` : 'N/A' },
                        { label: 'P/E Ratio', value: stockItem.peRatio ? stockItem.peRatio.toFixed(2) : 'N/A' },
                        { label: 'Dividend Yield', value: stockItem.dividendYield ? `${(stockItem.dividendYield * 100).toFixed(2)}%` : 'N/A' },
                        { label: '52 Week High', value: stockItem.high52Week ? `$${stockItem.high52Week.toFixed(2)}` : 'N/A' },
                        { label: '52 Week Low', value: stockItem.low52Week ? `$${stockItem.low52Week.toFixed(2)}` : 'N/A' },
                        { label: 'Volume', value: stockItem.volume ? stockItem.volume.toLocaleString() : 'N/A' },
                      ].map((stat, index) => (
                        <div key={index} className="text-sm">
                          <p className="text-gray-500 dark:text-gray-400">{stat.label}</p>
                          <p className="font-semibold text-gray-900 dark:text-white">{stat.value}</p>
                        </div>
                      ))}
                    </div>
                  </Tab.Panel>
                  <Tab.Panel className={`rounded-xl bg-white p-3 dark:bg-gray-800`}>
                    {stockItem.recentNews && stockItem.recentNews.length > 0 ? (
                      <ul className="space-y-4">
                        {stockItem.recentNews.map((news, index) => (
                          <li key={index} className="border-b border-gray-200 dark:border-gray-700 pb-4 last:border-b-0">
                            <Link 
                              to={`/news/${news.id}`} 
                              className="block hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded-md p-2 -m-2"
                            >
                              <div className="flex justify-between items-start">
                                <h3 className="text-sm font-semibold text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400">
                                  {news.title}
                                </h3>
                                <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-400 flex-shrink-0 ml-2" />
                              </div>
                              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                                {new Date(news.date).toLocaleDateString()} - {news.source}
                              </p>
                              <p className="mt-2 text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
                                {news.summary}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-sm text-gray-500 dark:text-gray-400">No recent news available.</p>
                    )}
                  </Tab.Panel>
                  <Tab.Panel className={`rounded-xl bg-white p-3 dark:bg-gray-800`}>
                    {stockItem.financialData ? (
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {[
                          { label: 'Revenue', value: stockItem.financialData.revenue ? `$${stockItem.financialData.revenue.toLocaleString()}` : 'N/A' },
                          { label: 'Net Income', value: stockItem.financialData.netIncome ? `$${stockItem.financialData.netIncome.toLocaleString()}` : 'N/A' },
                          { label: 'EPS', value: stockItem.financialData.eps ? `$${stockItem.financialData.eps.toFixed(2)}` : 'N/A' },
                          { label: 'Debt to Equity', value: stockItem.financialData.debtToEquity ? stockItem.financialData.debtToEquity.toFixed(2) : 'N/A' },
                          { label: 'Free Cash Flow', value: stockItem.financialData.freeCashFlow ? `$${stockItem.financialData.freeCashFlow.toLocaleString()}` : 'N/A' },
                          { label: 'Profit Margin', value: stockItem.financialData.profitMargin ? `${(stockItem.financialData.profitMargin * 100).toFixed(2)}%` : 'N/A' },
                        ].map((item, index) => (
                          <div key={index} className="text-sm">
                            <p className="text-gray-500 dark:text-gray-400">{item.label}</p>
                            <p className="font-semibold text-gray-900 dark:text-white">{item.value}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500 dark:text-gray-400">No financial data available.</p>
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </article>
          
          {/* Analyst Crew section */}
          <AnalystCrew
            analysts={stockItem.analysts}
            activeAnalyst={activeAnalyst}
            isAnalyzing={isAnalyzing}
            handleAnalystSelect={handleAnalystSelect}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showTooltip={showTooltip}
            tooltipContent={tooltipContent}
            analystReport={analystReport}
            reportRef={reportRef}
            itemType="stock"
          />
        </div>

        <div className="lg:w-1/4">
          {/* Watchlist component */}
          <div 
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md p-4 sticky"
            style={{ top: `${headerHeight + 16}px` }}
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800 dark:text-white">
              <StarIcon className="h-5 w-5 mr-2 text-yellow-500" />
              Watchlist
            </h2>
            <Watchlist
              watchlist={watchlist}
              removeFromWatchlist={removeFromWatchlist}
              addToWatchlist={addToWatchlist}
              lastUpdatedType={lastUpdatedType}
              setHomePageTab={handleSetHomePageTab}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StockDetailPage;
