import React, { useEffect } from 'react'
import Layout from '../components/layout/Layout'
import { useTheme } from '../contexts/ThemeContext'

const AboutUsPage = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className={`py-6 ${isDarkMode ? 'dark' : ''}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Empowering Your Investment Decisions
            </p>
            <p className="mt-3 max-w-2xl text-lg text-gray-500 dark:text-gray-400 lg:mx-auto">
              At ApexTrader, we're dedicated to providing cutting-edge stock analysis tools and real-time market data to help you make informed investment decisions.
            </p>
          </div>

          <div className="mt-8">
            <dl className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-8">
              {[
                {
                  name: 'Advanced Analytics',
                  description: 'Our platform leverages state-of-the-art algorithms to provide you with accurate and timely stock analysis.',
                },
                {
                  name: 'Real-time Data',
                  description: 'Stay ahead of the market with our real-time data feeds from major exchanges worldwide.',
                },
                {
                  name: 'Expert Insights',
                  description: 'Gain valuable insights from our team of experienced financial analysts and market experts.',
                },
                {
                  name: 'User-Friendly Interface',
                  description: 'Our intuitive interface makes it easy for both beginners and experienced traders to navigate and utilize our powerful tools.',
                },
              ].map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-400">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUsPage;