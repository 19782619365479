import React, { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { useTheme } from '../contexts/ThemeContext';

const TermsOfServicePage = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className={`py-8 ${isDarkMode ? 'dark' : ''}`}>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Terms of Service</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">1. Acceptance of Terms</h2>
            <p className="text-gray-600 dark:text-gray-400">
              By accessing or using ApexTrader's services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">2. Description of Services</h2>
            <p className="text-gray-600 dark:text-gray-400">
              ApexTrader provides real-time market data, advanced analytics tools, and personalized investment strategies. Our services are for informational purposes only and do not constitute financial advice.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">3. User Accounts</h2>
            <p className="text-gray-600 dark:text-gray-400">
              You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">4. User Conduct</h2>
            <p className="text-gray-600 dark:text-gray-400">
              Users agree not to use the service for any unlawful purpose or in any way that could damage, disable, or impair the service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">5. Intellectual Property</h2>
            <p className="text-gray-600 dark:text-gray-400">
              All content and materials available on ApexTrader are the property of ApexTrader or its licensors and are protected by copyright, trademark, and other intellectual property laws.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">6. Disclaimer of Warranties</h2>
            <p className="text-gray-600 dark:text-gray-400">
              ApexTrader provides its services "as is" and "as available" without any warranties, either express or implied.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">7. Limitation of Liability</h2>
            <p className="text-gray-600 dark:text-gray-400">
              ApexTrader shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">8. Modifications to Terms</h2>
            <p className="text-gray-600 dark:text-gray-400">
              ApexTrader reserves the right to modify these Terms of Service at any time. We will notify users of any significant changes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">9. Governing Law</h2>
            <p className="text-gray-600 dark:text-gray-400">
              These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
            </p>
          </section>

          <p className="text-gray-600 dark:text-gray-400 mt-8">
            Last updated: [Date]
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfServicePage;