import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useTheme } from '../contexts/ThemeContext';
import { EnvelopeIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { authApi } from '../services/api';
import { toast } from 'react-toastify';

const ForgotPasswordPage = () => {
  const { isDarkMode } = useTheme();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    setIsSubmitting(true);
    try {
      await authApi.requestPasswordReset(email);
      setIsSubmitted(true);
      toast.success('Password reset instructions have been sent to your email');
    } catch (error) {
      toast.error(error.message || 'Failed to request password reset');
    } finally {
      setIsSubmitting(false);
    }
  }, [email]);

  return (
    <Layout>
      <div className={`flex ${isDarkMode ? 'dark' : ''}`}>
        <div className="w-full max-w-4xl mx-auto flex bg-white dark:bg-gray-800 shadow-lg">
          {/* Left side - Form */}
          <div className="w-1/2 p-8">
            {!isSubmitted ? (
              <>
                <div className="mb-8">
                  <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                    Forgot Password
                  </h2>
                  <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
                    Enter your email address and we'll send you instructions to reset your password.
                  </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <InputField
                    id="email"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    icon={<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  />

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Sending...' : 'Send Reset Instructions'}
                  </button>
                </form>
              </>
            ) : (
              <div className="text-center">
                <div className="mb-6">
                  <EnvelopeIcon className="h-12 w-12 text-green-500 mx-auto" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                  Check Your Email
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  We've sent password reset instructions to:
                  <br />
                  <span className="font-medium text-gray-900 dark:text-white">{email}</span>
                </p>
              </div>
            )}

            <div className="mt-6 text-center">
              <Link
                to="/auth"
                className="inline-flex items-center text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />
                Back to Login
              </Link>
            </div>
          </div>

          {/* Right side - Additional content */}
          <div className="w-1/2 p-8 bg-gray-50 dark:bg-gray-700">
            <div className="mb-8">
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                Password Recovery
              </h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-400">
                <ListItem text="Check your email inbox" />
                <ListItem text="Click the reset link in the email" />
                <ListItem text="Create a new secure password" />
                <ListItem text="Log in with your new password" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Add these helper components
const InputField = ({ id, label, type, value, onChange, icon }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300" htmlFor={id}>
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        id={id}
        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white dark:bg-gray-700 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:text-white dark:border-gray-600"
        placeholder={label}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

const ListItem = ({ text }) => (
  <li className="flex items-center">
    <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
      <path d="M5 13l4 4L19 7"></path>
    </svg>
    {text}
  </li>
);

export default ForgotPasswordPage;
