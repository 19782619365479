import { useState } from 'react';
import { authApi } from '../services/api';

export const useAuthForm = (isLogin) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    try {
      let result;
      if (isLogin) {
        result = await authApi.login(email, password, rememberMe);
      } else {
        result = await authApi.register(email, password, name);
      }

      return { success: true, access_token: result.access_token };
    } catch (error) {
      console.error('Error during form submission:', error);
      setError(error.message || 'An unexpected error occurred. Please try again.');
      return { success: false };
    }
  };

  return { email, setEmail, password, setPassword, name, setName, rememberMe, setRememberMe, error, setError, handleSubmit };
};
