const TOKEN_KEY = 'auth_token';

export const authToken = {
  set: (token) => localStorage.setItem(TOKEN_KEY, token),
  setSession: (token) => sessionStorage.setItem(TOKEN_KEY, token),
  get: () => localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY),
  remove: () => {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
  },
};
