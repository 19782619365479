import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid'
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { CalendarIcon, NewspaperIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'

// Add this function at the top of the file
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const NewsItemDetailed = ({ 
  newsItem, 
  isInWatchlist, 
  onWatchlistToggle, 
  className = ''
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.15, ease: "easeInOut" }}
      className={`bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow duration-200 rounded-lg overflow-hidden ${className}`}
    >
      <div className="p-4 space-y-3">
        <div className="flex items-start justify-between">
          <h3 className="font-semibold text-gray-900 dark:text-white text-base leading-tight">
            <Link to={`/news/${newsItem.id}`} className="hover:text-blue-600 dark:hover:text-blue-400">
              {newsItem.title}
            </Link>
          </h3>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onWatchlistToggle}
            className="ml-2 text-gray-400 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors duration-200"
          >
            {isInWatchlist ? (
              <StarIconSolid className="h-5 w-5 text-yellow-400" />
            ) : (
              <StarIconOutline className="h-5 w-5" />
            )}
          </motion.button>
        </div>
        <div className="flex items-center space-x-4 text-xs text-gray-500 dark:text-gray-400">
          <div className="flex items-center">
            <NewspaperIcon className="h-4 w-4 mr-1" />
            <span>{newsItem.category}</span>
          </div>
          <div className="flex items-center">
            <CalendarIcon className="h-4 w-4 mr-1" />
            <span>{formatDate(newsItem.date)}</span>
          </div>
        </div>
        <div className="bg-blue-50 dark:bg-blue-900/50 p-3 rounded-lg">
          <div className="flex items-center space-x-2 mb-2">
            <UserCircleIcon className="h-5 w-5 text-blue-500 dark:text-blue-400" />
            <h4 className="text-sm font-semibold text-blue-800 dark:text-blue-200">Investor Impact</h4>
          </div>
          <p className="text-sm text-blue-700 dark:text-blue-300">{newsItem.impact}</p>
        </div>
        <div className="pt-2">
          <Link
            to={`/news/${newsItem.id}`}
            className="text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
          >
            Read full article
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

NewsItemDetailed.propTypes = {
  newsItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    impact: PropTypes.string.isRequired,
  }).isRequired,
  isInWatchlist: PropTypes.bool.isRequired,
  onWatchlistToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default NewsItemDetailed