import { useState, useEffect } from 'react';

export const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector('header');
    if (header) {
      setHeaderHeight(header.offsetHeight);
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setHeaderHeight(entry.target.offsetHeight);
      }
    });

    if (header) {
      resizeObserver.observe(header);
    }

    return () => {
      if (header) {
        resizeObserver.unobserve(header);
      }
    };
  }, []);

  return headerHeight;
};