import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DarkModeToggle from '../common/DarkModeToggle'
import { useTheme } from '../../contexts/ThemeContext'
import { useAuth } from '../../contexts/AuthContext'
import { navLinks } from '../../data/navLinks'
import { UserCircleIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import { motion, AnimatePresence } from 'framer-motion'
import { Dialog } from '@headlessui/react'

export default function Header() {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const handleLogout = () => {
    setIsLogoutDialogOpen(true);
    setIsMenuOpen(false);
  };

  const confirmLogout = () => {
    logout();
    setIsLogoutDialogOpen(false);
  };

  return (
    <>
      <header className={`${isDarkMode 
        ? 'bg-gradient-to-r from-gray-900 to-gray-800 text-gray-100' 
        : 'bg-gradient-to-r from-slate-800 to-slate-700 text-white'
      } shadow-lg`}>
        <div className="mx-auto px-4 py-4 flex justify-between items-center max-w-full">
          <Link to="/" className="flex items-center space-x-4">
            <img src="https://via.placeholder.com/40" alt="ApexTrader Logo" className="h-10 w-10 rounded-full border-2 border-emerald-500" />
            <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-emerald-400 to-blue-500">ApexTrader</h1>
          </Link>
          <nav className="hidden md:flex space-x-6 flex-grow justify-center">
            {navLinks.map(({ to, label }) => (
              <Link 
                key={to} 
                to={to} 
                className={`px-3 py-2 rounded-md transition duration-150 ease-in-out
                  ${location.pathname === to
                    ? isDarkMode ? 'text-white bg-gray-700' : 'text-white bg-slate-600'
                    : isDarkMode ? 'text-gray-300 hover:text-white hover:bg-gray-700' : 'text-gray-300 hover:text-white hover:bg-slate-600'
                  }`}
              >
                {label}
              </Link>
            ))}
          </nav>
          <div className="flex items-center space-x-4">
            <DarkModeToggle />
            {user ? (
              <div className="relative" ref={menuRef}>
                <button
                  onClick={handleMenuToggle}
                  className="flex items-center space-x-2 focus:outline-none transition duration-150 ease-in-out hover:bg-gray-700 dark:hover:bg-gray-600 rounded-md px-3 py-2"
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen}
                >
                  {user.icon ? (
                    <img
                      src={user.icon}
                      alt="User icon"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  ) : (
                    <UserCircleIcon className="h-8 w-8 text-gray-300" />
                  )}
                  <span className="hidden md:inline">{user.name}</span>
                  <ChevronDownIcon className={`h-4 w-4 transition-transform duration-200 ${isMenuOpen ? 'transform rotate-180' : ''}`} />
                </button>
                <AnimatePresence>
                  {isMenuOpen && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={menuVariants}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50"
                    >
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
                      >
                        Logout
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              <Link
                to="/auth"
                className="px-3 py-2 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </header>

      <Dialog
        open={isLogoutDialogOpen}
        onClose={() => setIsLogoutDialogOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl ring-1 ring-black ring-opacity-5">
            <Dialog.Title className="text-xl font-semibold text-gray-900 dark:text-gray-100">Confirm Logout</Dialog.Title>
            <Dialog.Description className="mt-3 text-sm text-gray-500 dark:text-gray-400">
              Are you sure you want to log out of your account?
            </Dialog.Description>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-150 ease-in-out"
                onClick={() => setIsLogoutDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 transition duration-150 ease-in-out"
                onClick={confirmLogout}
              >
                Logout
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}
