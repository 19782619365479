import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { userApi } from '../services/api';
import { authToken } from '../utils/authToken';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = useCallback(() => {
    authToken.remove();
    setUser(null);
  }, []);

  const fetchUser = useCallback(async () => {
    const token = authToken.get();
    if (token) {
      try {
        const userData = await userApi.getCurrent();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        logout();
      }
    }
    setLoading(false);
  }, [logout]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const login = useCallback(async (token, rememberMe) => {
    if (rememberMe) {
      authToken.set(token);
    } else {
      authToken.setSession(token);
    }
    await fetchUser();
  }, [fetchUser]);

  const updateUser = useCallback((updatedUserData) => {
    setUser(prevUser => ({ ...prevUser, ...updatedUserData }));
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
