import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Watchlist from '../components/dashboard/Watchlist';
import { useTheme } from '../contexts/ThemeContext';
import { StarIcon, CalendarIcon, NewspaperIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { useWatchlist } from '../contexts/WatchlistContext';
import SearchBar from '../components/common/SearchBar';
import { useNewsDetailLogic } from '../hooks/useNewsDetailLogic';
import Breadcrumb from '../components/layout/Breadcrumb';
import AnalystCrew from '../components/shared/AnalystCrew';
import { ExclamationTriangleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { newsApi } from '../services/api';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const NewsNotFound = ({ id }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] text-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full"
      >
        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8">
          <ExclamationTriangleIcon className="h-16 w-16 text-yellow-500 mx-auto mb-6" />
          <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">News Not Found</h1>
          <p className="text-lg mb-8 text-gray-600 dark:text-gray-400">
            We couldn't find any news article with the ID "{id}".
          </p>
          <Link
            to="/"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            Go Home
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

const NewsDetailPage = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isDarkMode } = useTheme();
  const { watchlist, lastUpdatedType, addToWatchlist, removeFromWatchlist } = useWatchlist();
  const {
    isInWatchlist,
    headerHeight,
    activeAnalyst,
    analystReport,
    reportRef,
    tooltipContent,
    isAnalyzing,
    showTooltip,
    setHomePageTab,
    handleSearch,
    handleAnalystSelect,
    handleShare,
    handleMouseEnter,
    handleMouseLeave,
  } = useNewsDetailLogic(id, newsItem);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const data = await newsApi.getNewsDetail(id);
        setNewsItem(data);
      } catch (error) {
        console.error('Error fetching news item:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    };

    fetchNewsItem();
  }, [id]);

  const handleWatchlistToggle = useCallback(() => {
    if (isInWatchlist) {
      removeFromWatchlist(newsItem, 'news');
    } else {
      addToWatchlist(newsItem, 'news');
    }
  }, [isInWatchlist, addToWatchlist, removeFromWatchlist, newsItem]);

  const handleSetHomePageTab = (tab) => {
    navigate('/', { state: { activeTab: tab } });
  };

  if (isLoading) {
    return (
      <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
        <div className="flex flex-col items-center justify-center min-h-[60vh]">
          <ArrowPathIcon className="w-20 h-20 text-blue-500 animate-spin mb-4" />
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
            Loading News Article
          </h2>
          <p className="text-gray-500 dark:text-gray-400">
            Please wait while we fetch the latest data for this news article...
          </p>
        </div>
      </Layout>
    );
  }

  if (!newsItem) {
    return (
      <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
        <NewsNotFound id={id} />
      </Layout>
    );
  }

  return (
    <Layout 
      searchBar={<SearchBar onSearch={handleSearch} />} 
      newsItem={newsItem}
      breadcrumb={
        <Breadcrumb 
          newsItem={newsItem} 
          setHomePageTab={setHomePageTab}
        />
      }
    >
      <div className={`flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-2 ${isDarkMode ? 'dark' : ''}`}>
        <div className="lg:w-3/4">
          {/* News article section */}
          <article className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md overflow-hidden mb-6">
            <div className="p-6 relative">
              {/* Action buttons */}
              <div className="absolute top-2 right-2 flex">
                {/* Watchlist toggle button */}
                <button 
                  onClick={handleWatchlistToggle}
                  className="p-1 text-gray-600 dark:text-gray-400 hover:text-yellow-500 dark:hover:text-yellow-400 transition-colors duration-200"
                  aria-label={isInWatchlist ? "Remove from watchlist" : "Add to watchlist"}
                >
                  {isInWatchlist ? (
                    <StarIconSolid className="h-5 w-5 text-yellow-500" />
                  ) : (
                    <StarIcon className="h-5 w-5" />
                  )}
                </button>
                {/* Share button */}
                <button 
                  onClick={handleShare}
                  className="p-1 text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-200"
                  aria-label="Share"
                >
                  <ArrowUpOnSquareIcon className="h-5 w-5" />
                </button>
              </div>

              <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">{newsItem.title}</h1>
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-400 mb-6">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <CalendarIcon className="h-5 w-5 mr-2" />
                    <span>{new Date(newsItem.date).toLocaleDateString()}</span>
                  </div>
                  <div className="flex items-center">
                    <NewspaperIcon className="h-5 w-5 mr-2" />
                    <span>{newsItem.category}</span>
                  </div>
                </div>
              </div>
              <div className="prose dark:prose-invert max-w-none">
                <p className="text-lg mb-6 text-gray-800 dark:text-gray-200">{newsItem.content}</p>
              </div>
            </div>
          </article>
          
          {/* Analyst Crew section */}
          <AnalystCrew
            analysts={newsItem.analysts}
            activeAnalyst={activeAnalyst}
            isAnalyzing={isAnalyzing}
            handleAnalystSelect={handleAnalystSelect}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showTooltip={showTooltip}
            tooltipContent={tooltipContent}
            analystReport={analystReport}
            reportRef={reportRef}
            itemType="news"
          />
        </div>

        <div className="lg:w-1/4">
          {/* Watchlist component */}
          <div 
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md p-4 sticky"
            style={{ top: `${headerHeight + 16}px` }}
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800 dark:text-white">
              <StarIcon className="h-5 w-5 mr-2 text-yellow-500" />
              Watchlist
            </h2>
            <Watchlist
              watchlist={watchlist}
              removeFromWatchlist={removeFromWatchlist}
              addToWatchlist={addToWatchlist}
              lastUpdatedType={lastUpdatedType}
              setHomePageTab={handleSetHomePageTab}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsDetailPage;
