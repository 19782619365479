import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { HomeIcon } from '@heroicons/react/24/outline';

const Breadcrumb = ({ newsItem, stockItem, setHomePageTab }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Filter out sensitive paths
  const filteredPathnames = pathnames.map(name => {
    // If the path is after 'reset-password', replace it with a generic message
    if (pathnames.includes('reset-password')) {
      return 'Reset Password';
    }
    return name;
  }).filter((name, index, array) => {
    // Remove duplicate 'Reset Password' entries
    if (name === 'Reset Password') {
      return array.indexOf(name) === index;
    }
    return true;
  });

  return (
    <nav className="py-2 px-4 bg-white dark:bg-gray-800">
      <ol className="list-none p-0 inline-flex items-center text-sm">
        <li className="flex items-center">
          <Link to="/" className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white">
            <HomeIcon className="h-5 w-5" />
          </Link>
        </li>
        {filteredPathnames.map((name, index) => {
          const isLast = index === filteredPathnames.length - 1;
          
          let displayName = name;
          if (isLast && (newsItem || stockItem)) {
            displayName = newsItem ? newsItem.title : stockItem.symbol;
            displayName = displayName.length > 30 ? displayName.substring(0, 30) + '...' : displayName;
          }

          return (
            <li key={name} className="flex items-center">
              <ChevronRightIcon className="h-4 w-4 text-gray-400 mx-2" />
              {isLast ? (
                <span className="text-gray-800 dark:text-gray-200 font-medium">{displayName}</span>
              ) : (
                <Link 
                  to="/" 
                  onClick={() => setHomePageTab(name === 'news' ? 'breaking-news' : 'top-stocks')}
                  className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-200"
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
