import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { UserCircleIcon, CpuChipIcon } from '@heroicons/react/24/solid';
import { ClockIcon, ChartBarIcon, LightBulbIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';

const AnalystCrew = ({
  analysts,
  activeAnalyst,
  isAnalyzing,
  handleAnalystSelect,
  handleMouseEnter,
  handleMouseLeave,
  showTooltip,
  tooltipContent,
  analystReport,
  reportRef,
  itemType,
  handleShareReport
}) => {
  return (
    <section className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md overflow-hidden mt-6">
      <h2 className="text-xl font-semibold p-4 text-gray-900 dark:text-white flex items-center border-b border-gray-200 dark:border-gray-700">
        <CpuChipIcon className="h-5 w-5 mr-2 text-blue-500" />
        Analyst Crew
      </h2>
      <div className="p-4">
        <div className="flex flex-wrap items-center gap-4 mb-6">
          {analysts.map((analyst, index) => (
            <motion.div
              key={index}
              className={`relative cursor-pointer ${isAnalyzing && activeAnalyst !== analyst ? 'opacity-50' : ''}`}
              onClick={() => handleAnalystSelect(analyst)}
              whileHover={{ scale: isAnalyzing && activeAnalyst !== analyst ? 1 : 1.05 }}
              whileTap={{ scale: isAnalyzing && activeAnalyst !== analyst ? 1 : 0.95 }}
              data-tooltip-id="analyst-tooltip"
              onMouseEnter={() => handleMouseEnter(analyst)}
              onMouseLeave={handleMouseLeave}
            >
              <div className={`rounded-full overflow-hidden border-2 transition-all duration-300 ${
                activeAnalyst === analyst 
                  ? 'w-16 h-16 border-blue-500 shadow-lg' 
                  : isAnalyzing 
                    ? 'w-12 h-12 border-gray-300 dark:border-gray-600' 
                    : 'w-14 h-14 border-gray-300 dark:border-gray-600 hover:border-blue-300 dark:hover:border-blue-400'
              }`}>
                <UserCircleIcon className="w-full h-full text-gray-400 dark:text-gray-500" />
              </div>
              {activeAnalyst === analyst && (
                <motion.div
                  className="absolute -bottom-1 -right-1 bg-blue-500 rounded-full w-5 h-5 flex items-center justify-center"
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                >
                  <span className="text-white text-xs">
                    {isAnalyzing ? '🤔' : '✓'}
                  </span>
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>
        {showTooltip && tooltipContent && (
          <Tooltip
            id="analyst-tooltip"
            place="top"
            effect="solid"
            className="z-50 !bg-gray-800 !text-white !p-3 !rounded !text-sm !max-w-xs !opacity-100"
          >
            {tooltipContent.split('\n').map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </Tooltip>
        )}
        <AnimatePresence>
          {activeAnalyst && (
            <motion.div
              className="bg-gray-100 dark:bg-gray-700 rounded-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              ref={reportRef}
            >
              <div className="bg-gray-200 dark:bg-gray-600 p-4 flex items-center justify-between">
                <div className="flex items-center">
                  <UserCircleIcon className="h-10 w-10 text-blue-500 mr-3" />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{activeAnalyst.name}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{activeAnalyst.specialty}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-300 mr-4">
                    <ClockIcon className="h-4 w-4 mr-1" />
                    <span>{new Date().toLocaleTimeString()}</span>
                  </div>
                  <button
                    onClick={handleShareReport}
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-200"
                    title="Share Report"
                    aria-label="Share Report"
                  >
                    <ArrowUpOnSquareIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="p-4">
                {isAnalyzing ? (
                  <div className="flex flex-col items-center justify-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-4"></div>
                    <p className="text-gray-700 dark:text-gray-300">Analyzing... Please wait.</p>
                  </div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <div className="flex items-center mb-4">
                      <ChartBarIcon className="h-5 w-5 text-blue-500 mr-2" />
                      <h4 className="text-md font-semibold text-gray-800 dark:text-white">Analysis Summary</h4>
                    </div>
                    <p className="text-gray-700 dark:text-gray-300 mb-4">{analystReport}</p>
                    <div className="flex items-center mb-2">
                      <LightBulbIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      <h4 className="text-md font-semibold text-gray-800 dark:text-white">Key Takeaways</h4>
                    </div>
                    <ul className="list-none text-gray-700 dark:text-gray-300 pl-5">
                      {itemType === 'stock' ? (
                        <>
                          <li className="mb-2 flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Market trend:</strong> {activeAnalyst.marketTrend}</span>
                          </li>
                          <li className="mb-2 flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Sector performance:</strong> {activeAnalyst.sectorPerformance}</span>
                          </li>
                          <li className="flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Recommended action:</strong> {activeAnalyst.recommendedAction}</span>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="mb-2 flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Market impact:</strong> {activeAnalyst.marketImpact}</span>
                          </li>
                          <li className="mb-2 flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Sector affected:</strong> {activeAnalyst.sectorAffected}</span>
                          </li>
                          <li className="flex items-start">
                            <span className="text-blue-500 mr-2">•</span>
                            <span><strong>Recommended action:</strong> {activeAnalyst.recommendedAction}</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default AnalystCrew;