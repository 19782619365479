import React, { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { useTheme } from '../contexts/ThemeContext';
import { CheckIcon } from '@heroicons/react/24/solid';

const tiers = [
  {
    name: 'Starter',
    price: 29,
    features: ['Basic stock data', 'Limited API calls', 'Email support'],
  },
  {
    name: 'Pro',
    price: 99,
    features: ['Advanced stock data', 'Unlimited API calls', 'Priority support', 'Custom alerts'],
  },
  {
    name: 'Enterprise',
    price: 299,
    features: ['Full data access', 'Dedicated account manager', 'Custom integration', 'On-site training'],
  },
];

const PricingPage = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className={`py-6 ${isDarkMode ? 'dark' : ''}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-center">Pricing Plans</h1>
            <p className="mt-3 text-lg text-gray-500 dark:text-gray-400 sm:text-center">
              Choose the perfect plan for your needs. Unlock the power of our advanced stock analysis tools.
            </p>
          </div>
          <div className="mt-8 space-y-4 sm:mt-12 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
            {tiers.map((tier) => (
              <div key={tier.name} className="border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm divide-y divide-gray-200 dark:divide-gray-700">
                <div className="p-6">
                  <h2 className="text-xl leading-6 font-semibold text-gray-900 dark:text-white">{tier.name}</h2>
                  <p className="mt-4">
                    <span className="text-3xl font-extrabold text-gray-900 dark:text-white">${tier.price}</span>
                    <span className="text-base font-medium text-gray-500 dark:text-gray-400">/mo</span>
                  </p>
                  <button
                    className="mt-6 w-full bg-indigo-600 border border-transparent rounded-md py-2 px-6 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {/* Add your sign-up logic here */}}
                  >
                    Sign up
                  </button>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 dark:text-white uppercase tracking-wide">What's included</h3>
                  <ul className="mt-4 space-y-3">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        <span className="text-sm text-gray-500 dark:text-gray-400">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPage;