import { useState, useEffect, useCallback, useRef } from 'react';
import { useWatchlist } from '../contexts/WatchlistContext';
import { stocksApi } from '../services/api';

export const useStockDetailLogic = (id) => {
  const [stockItem, setStockItem] = useState(null);
  const { watchlist, addToWatchlist, removeFromWatchlist } = useWatchlist();
  const [headerHeight] = useState(0);
  const [activeAnalyst, setActiveAnalyst] = useState(null);
  const [analystReport, setAnalystReport] = useState(null);
  const reportRef = useRef(null);
  const [tooltipContent, setTooltipContent] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStockDetail = async () => {
      try {
        const data = await stocksApi.getStockDetail(id);
        setStockItem(data);
        setIsLoading(false);
      } catch (error) {
        setError(`Failed to fetch stock details for ${id}. Please try again later.`);
        setIsLoading(false);
      }
    };

    fetchStockDetail();
  }, [id]);

  const isInWatchlist = watchlist.stocks && watchlist.stocks.some(item => item.id === id);

  useEffect(() => {
    console.log('Current watchlist:', watchlist);
    console.log('Is in watchlist:', isInWatchlist);
  }, [watchlist, isInWatchlist]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setHomePageTab = useCallback(() => {
    console.log('Navigating to home page tab');
  }, []);

  const handleSearch = useCallback((query) => {
    console.log('Searching for:', query);
    // Implement search functionality here
  }, []);

  const handleAnalystSelect = useCallback((analyst) => {
    if (isAnalyzing) return;
    setIsAnalyzing(true);
    setActiveAnalyst(analyst);
    setAnalystReport(null);
    
    const thinkingDuration = 3000 + Math.random() * 2000;
    
    setTimeout(() => {
      setAnalystReport(analyst.perspective);
      setIsAnalyzing(false);
      
      setTimeout(() => {
        reportRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }, thinkingDuration);
  }, [isAnalyzing]);

  const handleShare = useCallback(() => {
    // Implement share functionality
    console.log('Sharing stock item');
  }, []);

  const handleWatchlistToggle = useCallback(() => {
    console.log('Toggling watchlist for item:', stockItem);
    if (isInWatchlist) {
      console.log('Removing from watchlist');
      removeFromWatchlist(stockItem, 'stock');
    } else {
      console.log('Adding to watchlist');
      addToWatchlist(stockItem, 'stock');
    }
  }, [isInWatchlist, stockItem, removeFromWatchlist, addToWatchlist]);

  const handleMouseEnter = useCallback((analyst) => {
    setTooltipContent(`${analyst.name}\n${analyst.role}\n${analyst.specialty}`);
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltipContent('');
    setShowTooltip(false);
  }, []);

  return {
    stockItem,
    isInWatchlist,
    headerHeight,
    activeAnalyst,
    analystReport,
    reportRef,
    tooltipContent,
    isAnalyzing,
    showTooltip,
    isLoading,
    error,
    setHomePageTab,
    handleSearch,
    handleAnalystSelect,
    handleShare,
    handleWatchlistToggle,
    handleMouseEnter,
    handleMouseLeave,
  };
};
