import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../components/layout/Layout'
import SearchBar from '../components/common/SearchBar'
import NewsList from '../components/dashboard/news/NewsList'
import StockList from '../components/dashboard/stocks/StockList'
import Watchlist from '../components/dashboard/Watchlist'
import { NewspaperIcon, ChartBarIcon, StarIcon } from '@heroicons/react/24/outline'
import { useTheme } from '../contexts/ThemeContext'
import { useWatchlist } from '../contexts/WatchlistContext'
import { useHomePageLogic } from '../hooks/useHomePageLogic'
import { useHeaderHeight } from '../hooks/useHeaderHeight'
import { stocksApi } from '../services/api'
import { newsApi } from '../services/api'

const tabs = [
  { id: 'breaking-news', label: 'Breaking News', icon: NewspaperIcon, component: NewsList },
  { id: 'top-stocks', label: 'Top Stocks', icon: ChartBarIcon, component: StockList },
];

const HomePage = () => {
  const { isDarkMode } = useTheme()
  const { watchlist, lastUpdatedType, addToWatchlist, removeFromWatchlist } = useWatchlist()
  const location = useLocation()
  const {
    activeTab,
    stocks,
    news,
    isLoading,
    handleTabChange,
    handleSearch,
  } = useHomePageLogic(stocksApi, newsApi);

  const headerHeight = useHeaderHeight();

  useEffect(() => {
    if (location.state?.activeTab) {
      handleTabChange(location.state.activeTab);
    }
  }, [location.state, handleTabChange]);

  const ActiveComponent = tabs.find(tab => tab.id === activeTab)?.component || NewsList;

  return (
    <Layout searchBar={<SearchBar onSearch={handleSearch} />}>
      <div className={`flex flex-col lg:flex-row lg:space-x-2 ${isDarkMode ? 'dark' : ''}`}>
        <div className="lg:w-3/4 flex flex-col mb-6 lg:mb-0">
          <div className="flex-grow bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-md">
            <div className="flex border-b dark:border-gray-700">
              {tabs.map((tab) => (
                <TabButton 
                  key={tab.id}
                  tab={tab}
                  isActive={activeTab === tab.id}
                  onClick={() => handleTabChange(tab.id)}
                />
              ))}
            </div>
            <div className="p-4 overflow-y-auto custom-scrollbar">
              <ActiveComponent 
                addToWatchlist={addToWatchlist} 
                removeFromWatchlist={removeFromWatchlist}
                watchlist={watchlist}
                stockData={stocks}
                newsData={news}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
        
        <div className="lg:w-1/4">
          <div 
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md p-4 sticky"
            style={{ top: `${headerHeight + 8}px` }}
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center text-gray-800 dark:text-white">
              <StarIcon className="h-5 w-5 mr-2 text-yellow-500" />
              Watchlist
            </h2>
            <Watchlist
              watchlist={watchlist}
              removeFromWatchlist={removeFromWatchlist}
              addToWatchlist={addToWatchlist}
              lastUpdatedType={lastUpdatedType}
              setHomePageTab={handleTabChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const TabButton = memo(({ tab, isActive, onClick }) => (
  <button 
    onClick={onClick}
    className={`
      flex-1 py-3 px-4 text-center font-medium transition duration-150 ease-in-out relative
      flex items-center justify-center space-x-2
      ${isActive 
        ? 'text-blue-600 dark:text-blue-400 border-b-2 border-blue-600 dark:border-blue-400 bg-blue-50 dark:bg-gray-700' 
        : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
      }
    `}
  >
    <tab.icon className="h-5 w-5" />
    <span>{tab.label}</span>
  </button>
));

export default HomePage
