import { client } from './client';
import { authToken } from '../utils/authToken';
import { mockStocks } from '../data/mockStocks';
import { mockNews } from '../data/mockNews';

export const authApi = {
  googleSignIn: (credential) => client.post('/auth/google', { credential }),
  login: (email, password) => client.post('/auth/login', { email, password }),
  register: (email, password, name) => client.post('/auth/register', { email, password, name }),
  logout: () => {
    authToken.remove();
  },
  requestPasswordReset: (email) => client.post('/auth/forgot-password', { email }),
  resetPassword: (token, newPassword) => client.post('/auth/reset-password', { 
    token, 
    new_password: newPassword 
  }),
};

export const userApi = {
  getCurrent: () => client.get('/user/current'),
  updateProfile: (userData) => client.put('/user/profile', userData),
  updateIcon: async (file) => client.upload('/user/icon', file),
  changePassword: (passwordData) => client.post('/user/change-password', passwordData),
};

export const stocksApi = {
  getStocks: async () => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
    return mockStocks;
  },

  getStockDetail: async (symbol) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
    const stock = mockStocks.find(s => s.symbol === symbol);
    if (!stock) {
      throw new Error(`Stock with symbol ${symbol} not found`);
    }
    return stock;
  },
};

export const newsApi = {
  getNews: async () => {
    await new Promise(resolve => setTimeout(resolve, 500));
    return mockNews;
  },

  getNewsDetail: async (id) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    const newsItem = mockNews.find(n => n.id === id);
    if (!newsItem) {
      throw new Error(`News item with id ${id} not found`);
    }
    return newsItem;
  },
};

export const searchApi = {
  search: async (query) => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 300));

    // Search stocks
    const stockResults = mockStocks.filter(stock => 
      stock.symbol.toLowerCase().includes(query.toLowerCase()) || 
      stock.name.toLowerCase().includes(query.toLowerCase())
    );

    // Search news
    const newsResults = mockNews.filter(news =>
      news.title.toLowerCase().includes(query.toLowerCase()) ||
      news.content.toLowerCase().includes(query.toLowerCase()) ||
      news.category.toLowerCase().includes(query.toLowerCase())
    );

    // Return the grouped search results
    return {
      stocks: stockResults,
      news: newsResults
    };
  },
};
