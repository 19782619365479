export const mockNews = [
    {
        id: '1',
        category: 'Technology',
        date: '2024-09-20',
        title: 'Global Tech Summit Announces Breakthrough in Quantum Computing',
        content: 'Scientists at the Global Tech Summit have unveiled a groundbreaking quantum computer capable of solving complex problems in minutes that would take traditional supercomputers years to process. This development marks a significant milestone in the field of quantum computing.',
        impact: 'This breakthrough could significantly affect tech stocks, particularly those involved in computing and data processing. Companies like IBM, Google, and Microsoft might see increased investor interest.',
        analysts: [
            {
                name: 'John Doe',
                role: 'Senior Analyst',
                specialty: 'Tech Stocks',
                perspective: 'This breakthrough could significantly affect tech stocks, particularly those involved in computing and data processing. Companies like IBM, Google, and Microsoft might see increased investor interest.'
            },
            {
                name: 'Jane Smith',
                role: 'Market Strategist',
                specialty: 'Emerging Markets',
                perspective: "While this is exciting news for the tech sector, it's important to consider the global impact. Emerging markets with strong tech sectors, like India and Taiwan, might also benefit from this development."
            },
            {
                name: 'Mike Johnson',
                role: 'Quantitative Analyst',
                specialty: 'Algorithmic Trading',
                perspective: "The potential for quantum computing to revolutionize algorithmic trading is immense. We might see a surge in demand for quantum-ready trading algorithms and infrastructure in the financial sector."
            }
        ]
    },
    {
        id: '2',
        category: 'Finance',
        date: '2024-09-21',
        title: 'Federal Reserve Announces Unexpected Interest Rate Cut',
        content: 'In a surprise move, the Federal Reserve has announced a 0.5% cut in interest rates, citing concerns about global economic slowdown. This decision aims to stimulate economic growth and maintain stability in financial markets.',
        impact: 'This unexpected move could lead to increased borrowing and spending, potentially boosting stocks in various sectors, particularly real estate and consumer goods.',
        analysts: [
            {
                name: 'Emily Chen',
                role: 'Economic Analyst',
                specialty: 'Monetary Policy',
                perspective: 'This unexpected move could lead to increased borrowing and spending, potentially boosting stocks in various sectors, particularly real estate and consumer goods.'
            },
            {
                name: 'David Lee',
                role: 'Market Analyst',
                specialty: 'Financial Markets',
                perspective: "While this decision might stimulate economic growth, it's essential to monitor the impact on inflation and the overall economy. We might see a shift in investor sentiment towards sectors that benefit from lower interest rates."
            },
            {
                name: 'Sarah Kim',
                role: 'Risk Management Specialist',
                specialty: 'Asset Allocation',
                perspective: "The potential for increased borrowing and spending could lead to a surge in asset prices. We should be cautious and adjust our asset allocation strategies accordingly to mitigate potential risks."
            }
        ]
    },
    {
        id: '3',
        category: 'Energy',
        date: '2024-09-22',
        title: 'Major Oil Discovery in Arctic Region Sparks Environmental Debate',
        content: 'A significant oil reserve has been discovered in the Arctic, estimated to contain billions of barrels. While this could potentially reshape global energy markets, it has also ignited fierce debate among environmentalists and policymakers about the ecological impact of Arctic drilling.',
        impact: 'Energy sector stocks may see volatility as investors weigh the economic potential against environmental risks and regulatory challenges. Companies involved in Arctic drilling might see increased investor interest.',
        analysts: [
            {
                name: 'James Brown',
                role: 'Energy Analyst',
                specialty: 'Oil and Gas',
                perspective: 'Energy sector stocks may see volatility as investors weigh the economic potential against environmental risks and regulatory challenges. Companies involved in Arctic drilling might see increased investor interest.'
            },
            {
                name: 'Lisa Nguyen',
                role: 'Sustainability Specialist',
                specialty: 'Environmental Impact',
                perspective: "The environmental concerns surrounding Arctic drilling are significant. We should consider the long-term implications of this discovery on the environment and the potential for regulatory changes."
            },
            {
                name: 'Kevin White',
                role: 'Geopolitical Analyst',
                specialty: 'Global Politics',
                perspective: "The geopolitical implications of this discovery are substantial. We might see increased tensions between nations vying for control of the Arctic region's resources."
            }
        ]
    },
    {
        id: '4',
        category: 'Healthcare',
        date: '2024-09-23',
        title: 'Breakthrough Gene Therapy Shows Promise in Treating Rare Genetic Disorders',
        content: 'Researchers have successfully treated a group of patients with a rare genetic disorder using a novel gene therapy approach. This treatment has shown remarkable results in early clinical trials, potentially offering hope for millions affected by similar conditions.',
        impact: 'Biotech and pharmaceutical companies involved in gene therapy research may see increased investor interest and potential stock price movements. Companies like Biogen and Novartis might benefit from this breakthrough.',
        analysts: [
            {
                name: 'Michael Davis',
                role: 'Biotech Analyst',
                specialty: 'Gene Therapy',
                perspective: 'Biotech and pharmaceutical companies involved in gene therapy research may see increased investor interest and potential stock price movements. Companies like Biogen and Novartis might benefit from this breakthrough.'
            },
            {
                name: 'Rachel Patel',
                role: 'Market Researcher',
                specialty: 'Medical Devices',
                perspective: "The potential for gene therapy to revolutionize the treatment of rare genetic disorders is immense. We might see a surge in demand for medical devices and equipment related to gene therapy."
            },
            {
                name: 'Brian Hall',
                role: 'Regulatory Specialist',
                specialty: 'Clinical Trials',
                perspective: "The regulatory landscape for gene therapy is complex. We should monitor the progress of clinical trials and potential regulatory approvals to understand the full impact of this breakthrough."
            }
        ]
    },
    {
        id: '5',
        category: 'Healthcare',
        date: '2024-09-24',
        title: 'Revolutionary Gene Editing Technique Promises New Era in Medicine',
        content: 'Scientists have developed a new gene editing technique that offers unprecedented precision and efficiency. This breakthrough could potentially cure a wide range of genetic diseases and revolutionize personalized medicine.',
        impact: 'This breakthrough could significantly boost biotech stocks, especially companies specializing in gene therapy and genetic research. Companies like CRISPR Therapeutics and Editas Medicine might see increased investor interest.',
        analysts: [
            {
                name: 'Daniel Lee',
                role: 'Genomics Analyst',
                specialty: 'Gene Editing',
                perspective: 'This breakthrough could significantly boost biotech stocks, especially companies specializing in gene therapy and genetic research. Companies like CRISPR Therapeutics and Editas Medicine might see increased investor interest.'
            },
            {
                name: 'Amanda Kim',
                role: 'Market Strategist',
                specialty: 'Healthcare Technology',
                perspective: "The potential for gene editing to revolutionize healthcare is immense. We might see a surge in demand for healthcare technology and equipment related to gene editing."
            },
            {
                name: 'Eric Martin',
                role: 'Risk Management Specialist',
                specialty: 'Biotech Investing',
                perspective: "The regulatory and ethical implications of gene editing are significant. We should be cautious and adjust our investment strategies accordingly to mitigate potential risks."
            }
        ]
    }
];