import React, { useState, useEffect } from 'react'
import NewsItemCompact from './news/NewsItemCompact'
import StockItemCompact from './stocks/StockItemCompact'
import { NewspaperIcon, ChartBarIcon, PlusCircleIcon, LightBulbIcon } from '@heroicons/react/24/outline'
import { motion, AnimatePresence } from 'framer-motion'

// Update TabButton component
const TabButton = ({ label, icon: Icon, isActive, onClick }) => (
  <button 
    onClick={onClick}
    className={`
      flex-1 py-3 px-4 text-center font-medium transition duration-150 ease-in-out
      flex items-center justify-center space-x-2 border-b-2
      ${isActive 
        ? 'text-blue-600 dark:text-blue-400 border-blue-600 dark:border-blue-400 bg-blue-50 dark:bg-gray-700' 
        : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 border-transparent hover:border-gray-300 dark:hover:border-gray-600'
      }
    `}
  >
    <Icon className="h-5 w-5" />
    <span>{label}</span>
  </button>
)

export default function Watchlist({ watchlist, removeFromWatchlist, addToWatchlist, lastUpdatedType, setHomePageTab }) {
  const [activeTab, setActiveTab] = useState('news');

  useEffect(() => {
    if (lastUpdatedType === 'news' || lastUpdatedType === 'stock') {
      setActiveTab(lastUpdatedType === 'stock' ? 'stocks' : 'news');
    }
  }, [lastUpdatedType]);

  const handleBrowseTopItems = (type) => {
    if (setHomePageTab) {
      setHomePageTab(type);
    }
  };

  const renderEmptyState = (type) => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="text-center py-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
    >
      <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
      <p className="mt-2 text-gray-600 dark:text-gray-300 font-medium">
        Your {type} watchlist is empty
      </p>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
        Start adding {type === 'stocks' ? 'stocks' : 'news'} by searching or browsing top {type}
      </p>
      <motion.button 
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
        onClick={() => handleBrowseTopItems(type === 'stocks' ? 'top-stocks' : 'breaking-news')}
      >
        Browse Top {type === 'stocks' ? 'Stocks' : 'News'}
      </motion.button>
    </motion.div>
  )

  const renderBothEmptyState = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="text-left p-8 bg-gray-50 dark:bg-gray-800 rounded-lg"
    >
      <div className="flex justify-center">
        <LightBulbIcon className="h-16 w-16 text-yellow-400" />
      </div>
      <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-gray-100 text-center">
        Welcome to Your Watchlist!
      </h3>
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 text-center">
        Your personalized space for tracking stocks and news is ready to go.
      </p>
      <div className="mt-6">
        <h4 className="font-medium text-gray-700 dark:text-gray-300">Getting Started:</h4>
        <ul className="mt-2 text-sm text-gray-500 dark:text-gray-400 space-y-2 list-disc pl-5">
          <li>Explore top stocks and breaking news</li>
          <li>Add interesting items to your watchlist</li>
          <li>Track your favorite stocks and news stories</li>
        </ul>
      </div>
    </motion.div>
  )

  const renderContent = () => {
    if (!watchlist || ((!watchlist.stocks || watchlist.stocks.length === 0) && (!watchlist.news || watchlist.news.length === 0))) {
      return renderBothEmptyState();
    }

    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.1, ease: "easeOut" }}
        >
          {activeTab === 'news' ? (
            watchlist.news && watchlist.news.length > 0 ? (
              <div className="space-y-2">
                {[...watchlist.news].reverse().map((newsItem) => (
                  <NewsItemCompact
                    key={newsItem.id}
                    newsItem={newsItem}
                    onRemove={() => handleWatchlistToggle(newsItem, 'news')}
                  />
                ))}
              </div>
            ) : renderEmptyState('news')
          ) : (
            watchlist.stocks && watchlist.stocks.length > 0 ? (
              <div className="space-y-2">
                {[...watchlist.stocks].reverse().map((stock) => (
                  <StockItemCompact
                    key={stock.id}
                    stock={stock}
                    onRemove={() => handleWatchlistToggle(stock, 'stock')}
                  />
                ))}
              </div>
            ) : renderEmptyState('stocks')
          )}
        </motion.div>
      </AnimatePresence>
    );
  }

  const handleWatchlistToggle = (item, type) => {
    if (type === 'stock') {
      if (watchlist.stocks.some(stock => stock.id === item.id)) {
        removeFromWatchlist(item, 'stock');
      } else {
        addToWatchlist(item, 'stock');
      }
    } else if (type === 'news') {
      if (watchlist.news.some(news => news.id === item.id)) {
        removeFromWatchlist(item, 'news');
      } else {
        addToWatchlist(item, 'news');
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow">
      {watchlist && (watchlist.stocks?.length > 0 || watchlist.news?.length > 0) && (
        <div className="flex border-b dark:border-gray-700">
          <TabButton 
            label="News"
            icon={NewspaperIcon}
            isActive={activeTab === 'news'}
            onClick={() => setActiveTab('news')}
          />
          <TabButton 
            label="Stocks"
            icon={ChartBarIcon}
            isActive={activeTab === 'stocks'}
            onClick={() => setActiveTab('stocks')}
          />
        </div>
      )}
      <div className="p-2">
        {renderContent()}
      </div>
    </div>
  )
}
