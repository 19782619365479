import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { WatchlistProvider } from './contexts/WatchlistContext';
import ErrorBoundary from './components/common/ErrorBoundary';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import AboutUsPage from './pages/AboutUsPage';
import NewsDetailPage from './pages/NewsDetailPage';
import StockDetailPage from './pages/StockDetailPage';
import AuthPage from './pages/AuthPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import NotFoundPage from './pages/NotFoundPage';
import { AuthProvider } from './contexts/AuthContext';
import ProfilePage from './pages/ProfilePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

function App() {
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <ThemeProvider>
            <WatchlistProvider>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/about" element={<AboutUsPage />} />
                  <Route path="/news/:id" element={<NewsDetailPage />} />
                  <Route path="/stock/:id" element={<StockDetailPage />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                  <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <ToastContainer position="top-right" autoClose={3000} />
              </ErrorBoundary>
            </WatchlistProvider>
          </ThemeProvider>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
