import React, { useEffect } from 'react';
import Layout from '../components/layout/Layout';
import { useTheme } from '../contexts/ThemeContext';

const PrivacyPolicyPage = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className={`py-8 ${isDarkMode ? 'dark' : ''}`}>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Privacy Policy</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">1. Information We Collect</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We collect personal information that you provide to us, such as your name, email address, and financial information when you create an account or use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">2. How We Use Your Information</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We use your information to provide and improve our services, communicate with you, and comply with legal obligations.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">3. Information Sharing and Disclosure</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We do not sell your personal information. We may share your information with third-party service providers who help us operate our business.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">4. Data Security</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">5. Your Rights and Choices</h2>
            <p className="text-gray-600 dark:text-gray-400">
              You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to certain processing of your data.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">6. Cookies and Tracking Technologies</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We use cookies and similar tracking technologies to collect information about your browsing activities and to improve your experience on our platform.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">7. Third-Party Links</h2>
            <p className="text-gray-600 dark:text-gray-400">
              Our service may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">8. Children's Privacy</h2>
            <p className="text-gray-600 dark:text-gray-400">
              Our services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 18.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">9. Changes to This Privacy Policy</h2>
            <p className="text-gray-600 dark:text-gray-400">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">10. Contact Us</h2>
            <p className="text-gray-600 dark:text-gray-400">
              If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].
            </p>
          </section>

          <p className="text-gray-600 dark:text-gray-400 mt-8">
            Last updated: [Date]
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;