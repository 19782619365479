import React, { useState, useCallback } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { LockClosedIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { authApi } from '../services/api';
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    
    if (!token) {
      toast.error('Invalid reset token');
      return;
    }

    // Remove any potential '/' from the start of the token if present
    const cleanToken = token.startsWith('/') ? token.slice(1) : token;

    if (!newPassword || !confirmPassword) {
      toast.error('Please fill in all fields');
      return;
    }

    // Add password strength validation
    if (newPassword.length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }

    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(newPassword)) {
      toast.error('Password must contain at least one uppercase letter, one lowercase letter, and one number');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setIsSubmitting(true);
    try {
      // Use the cleaned token
      await authApi.resetPassword(cleanToken, newPassword);
      toast.success('Password has been reset successfully');
      navigate('/auth');
    } catch (error) {
      toast.error(error.message || 'Failed to reset password');
    } finally {
      setIsSubmitting(false);
    }
  }, [token, newPassword, confirmPassword, navigate]);

  if (!token) {
    return (
      <Layout>
        <div className="text-center mt-8">
          <h2 className="text-2xl font-bold text-red-600">Invalid Reset Link</h2>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            This password reset link is invalid or has expired.
          </p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex">
        <div className="w-full max-w-4xl mx-auto flex bg-white dark:bg-gray-800 shadow-lg">
          <div className="w-full p-8">
            <div className="mb-8">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                Reset Your Password
              </h2>
              <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
                Please enter your new password below.
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <InputField
                id="new-password"
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                icon={<LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              />

              <InputField
                id="confirm-password"
                label="Confirm New Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                icon={<LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              />

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? 'Resetting...' : 'Reset Password'}
              </button>
            </form>

            <div className="mt-6 text-center">
              <Link
                to="/auth"
                className="inline-flex items-center text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Add these helper components
const InputField = ({ id, label, type, value, onChange, icon }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300" htmlFor={id}>
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        id={id}
        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white dark:bg-gray-700 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:text-white dark:border-gray-600"
        placeholder={label}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

export default ResetPasswordPage;
