import { useState, useEffect, useCallback, useRef } from 'react';
import { useWatchlist } from '../contexts/WatchlistContext';
import { useHeaderHeight } from './useHeaderHeight';

export const useNewsDetailLogic = (id, newsItem) => {
  const { watchlist, addToWatchlist, removeFromWatchlist } = useWatchlist();
  const [activeAnalyst, setActiveAnalyst] = useState(null);
  const [analystReport, setAnalystReport] = useState(null);
  const reportRef = useRef(null);
  const [tooltipContent, setTooltipContent] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const headerHeight = useHeaderHeight();

  const isInWatchlist = watchlist.news && watchlist.news.some(item => item.id === id);

  useEffect(() => {
    console.log('Current watchlist:', watchlist);
    console.log('Is in watchlist:', isInWatchlist);
  }, [watchlist, isInWatchlist]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setHomePageTab = useCallback(() => {
    console.log('Navigating to home page tab');
  }, []);

  const handleSearch = useCallback((query) => {
    console.log('Searching for:', query);
    // Implement search functionality here
  }, []);

  const handleAnalystSelect = useCallback((analyst) => {
    if (isAnalyzing) return;
    setIsAnalyzing(true);
    setActiveAnalyst(analyst);
    setAnalystReport(null);
    
    const thinkingDuration = 3000 + Math.random() * 2000;
    
    setTimeout(() => {
      setAnalystReport(analyst.perspective);
      setIsAnalyzing(false);
      
      setTimeout(() => {
        reportRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }, thinkingDuration);
  }, [isAnalyzing]);

  const handleShare = useCallback(() => {
    // Implement share functionality
    console.log('Sharing news item:', id);
  }, [id]);

  const handleWatchlistToggle = useCallback(() => {
    if (isInWatchlist) {
      removeFromWatchlist(newsItem, 'news');
    } else {
      addToWatchlist(newsItem, 'news');
    }
  }, [isInWatchlist, addToWatchlist, removeFromWatchlist, newsItem]);

  const handleMouseEnter = useCallback((analyst) => {
    setTooltipContent(analyst.description);
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return {
    isInWatchlist,
    headerHeight,
    activeAnalyst,
    analystReport,
    reportRef,
    tooltipContent,
    isAnalyzing,
    showTooltip,
    setHomePageTab,
    handleSearch,
    handleAnalystSelect,
    handleShare,
    handleWatchlistToggle,
    handleMouseEnter,
    handleMouseLeave,
  };
};
