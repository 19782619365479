import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useTheme } from '../contexts/ThemeContext';
import { UserIcon, EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { useAuthForm } from '../hooks/useAuthForm';
import { useAuth } from '../contexts/AuthContext';
import { authApi } from '../services/api';

const AuthPage = () => {
  const { isDarkMode } = useTheme();
  const [isLogin, setIsLogin] = useState(true);
  const { email, setEmail, password, setPassword, name, setName, rememberMe, setRememberMe, error, setError, handleSubmit } = useAuthForm(isLogin);
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const clearError = useCallback(() => {
    if (error) {
      setError('');
    }
  }, [error, setError]);

  const handleInputChange = useCallback((setter) => (value) => {
    setter(value);
    clearError();
  }, [clearError]);

  const handleGoogleSignIn = useCallback(async (response) => {
    clearError();
    const { credential } = response;
    try {
      const userData = await authApi.googleSignIn(credential);
      login(userData.access_token);
      navigate('/');
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setError(`Failed to sign in with Google: ${error.message || 'Unknown error'}`);
    }
  }, [navigate, login, setError, clearError]);

  const initializeGoogleSignIn = useCallback(() => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: '74213407798-k4fnvm2f0mrikp3qjq13qr817c2vfikv.apps.googleusercontent.com',
        callback: handleGoogleSignIn
      });

      const wrapper = document.getElementById('googleSignInButtonWrapper');
      const buttonWidth = wrapper ? wrapper.offsetWidth : 250;

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInButton'),
        { theme: 'outline', size: 'large', type: 'standard', width: buttonWidth }
      );
    } else {
      console.error('Google API not loaded');
    }
  }, [handleGoogleSignIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeGoogleSignIn();
  }, [initializeGoogleSignIn]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await handleSubmit();
    if (result.success) {
      await login(result.access_token, rememberMe);
      navigate('/');
    }
  };

  // Add this handler near your other handlers
  const handleForgotPassword = useCallback(() => {
    navigate('/forgot-password');
  }, [navigate]);

  return (
    <Layout>
      <div className={`flex ${isDarkMode ? 'dark' : ''}`}>
        <div className="w-full max-w-4xl mx-auto flex bg-white dark:bg-gray-800 shadow-lg">
          {/* Left side - Form */}
          <div className="w-1/2 p-8">
            <div className="mb-8">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
                {isLogin ? 'Welcome Back' : 'Create Account'}
              </h2>
              <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
                {isLogin ? 'Log in to access your account!' : 'Sign up to get started!'}
              </p>
            </div>
            <form onSubmit={onSubmit} className="space-y-6">
              {!isLogin && (
                <InputField
                  id="name"
                  label="Name"
                  type="text"
                  value={name}
                  onChange={handleInputChange(setName)}
                  icon={<UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                />
              )}
              <InputField
                id="email"
                label="Email Address"
                type="email"
                value={email}
                onChange={handleInputChange(setEmail)}
                icon={<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              />
              <InputField
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={handleInputChange(setPassword)}
                icon={<LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              />
              {isLogin && (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
                    >
                      Forgot your password?
                    </button>
                  </div>
                </div>
              )}
              {error && <p className="text-sm text-red-600 dark:text-red-400">{error}</p>}
              <SubmitButton label={isLogin ? 'Sign In' : 'Sign Up'} />
            </form>
            <AuthToggle isLogin={isLogin} setIsLogin={setIsLogin} />
          </div>

          {/* Right side - Additional content */}
          <div className="w-1/2 p-8 bg-gray-50 dark:bg-gray-700">
            <WhyChooseSection />
            <SocialSignIn />
            <TermsAndPrivacy />
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Add these new components at the end of the file
const InputField = ({ id, label, type, value, onChange, icon }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300" htmlFor={id}>
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        id={id}
        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white dark:bg-gray-700 placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:text-white dark:border-gray-600"
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  </div>
);

const SubmitButton = ({ label }) => (
  <button
    type="submit"
    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
  >
    {label}
  </button>
);

const AuthToggle = ({ isLogin, setIsLogin }) => (
  <div className="mt-6 text-center">
    <button
      onClick={() => setIsLogin(!isLogin)}
      className="text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
    >
      {isLogin ? "Don't have an account? Sign up" : "Already have an account? Sign in"}
    </button>
  </div>
);

const WhyChooseSection = () => (
  <div className="mb-8">
    <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
      Why Choose ApexTrader?
    </h3>
    <ul className="space-y-2 text-gray-600 dark:text-gray-400">
      {['Real-time market data', 'Advanced analytics tools', 'Personalized investment strategies', 'Expert financial insights'].map((item, index) => (
        <li key={index} className="flex items-center">
          <svg className="h-5 w-5 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M5 13l4 4L19 7"></path>
          </svg>
          {item}
        </li>
      ))}
    </ul>
  </div>
);

const SocialSignIn = () => (
  <div className="mt-8">
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
      </div>
      <div className="relative flex justify-center text-sm">
        <span className="px-2 bg-gray-50 dark:bg-gray-700 text-gray-500 dark:text-gray-400">
          Or continue with
        </span>
      </div>
    </div>
    <div className="mt-6">
      <div id="googleSignInButtonWrapper" className="w-full">
        <div id="googleSignInButton" className="w-full"></div>
      </div>
    </div>
  </div>
);

const TermsAndPrivacy = () => (
  <p className="mt-6 text-xs text-center text-gray-600 dark:text-gray-400">
    By signing up, you agree to our{' '}
    <Link to="/terms-of-service" className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
      Terms of Service
    </Link>{' '}
    and{' '}
    <Link to="/privacy-policy" className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
      Privacy Policy
    </Link>
    .
  </p>
);

export default AuthPage;
