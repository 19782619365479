import React from 'react';
import { Link } from 'react-router-dom';
import { StarIcon, UserCircleIcon, HandThumbUpIcon, HandThumbDownIcon, MinusIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';

const StockItemDetailed = ({ stock, isInWatchlist, onWatchlistToggle }) => {
  const { id, symbol, name, price, change, recommendation, summary } = stock;

  const getRecommendationColor = (rec) => {
    if (rec > 0) return 'text-green-600 dark:text-green-400';
    if (rec < 0) return 'text-red-600 dark:text-red-400';
    return 'text-gray-600 dark:text-gray-400';
  };

  const getRecommendationIcons = (rec) => {
    switch (rec) {
      case 2:
        return (
          <>
            <HandThumbUpIcon className="h-4 w-4" />
            <HandThumbUpIcon className="h-4 w-4" />
          </>
        );
      case 1:
        return <HandThumbUpIcon className="h-4 w-4" />;
      case 0:
        return <MinusIcon className="h-4 w-4" />;
      case -1:
        return <HandThumbDownIcon className="h-4 w-4" />;
      case -2:
        return (
          <>
            <HandThumbDownIcon className="h-4 w-4" />
            <HandThumbDownIcon className="h-4 w-4" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.15, ease: "easeInOut" }}
      className="h-full"
    >
      <Link to={`/stock/${id}`} className="block h-full">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-300 h-full flex flex-col">
          <div className="flex justify-between items-start mb-2">
            <div className="flex items-center">
              <div className="w-10 h-10 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden flex items-center justify-center mr-3">
                <img
                  src={`https://via.placeholder.com/40.png?text=${symbol}`}
                  alt={`${name} logo`}
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{symbol}</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">{name}</p>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={(e) => {
                e.preventDefault();
                onWatchlistToggle(stock);
              }}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
            >
              {isInWatchlist ? (
                <StarIconSolid className="h-5 w-5 text-yellow-500" />
              ) : (
                <StarIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
              )}
            </motion.button>
          </div>
          <div className="flex justify-between items-baseline mb-4">
            <span className="text-2xl font-bold text-gray-800 dark:text-gray-200">${price.toFixed(2)}</span>
            <span className={`text-sm font-medium px-2 py-1 rounded ${change > 0 ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}>
              {change > 0 ? '▲' : '▼'} {Math.abs(change)}%
            </span>
          </div>
          
          <div className="flex-grow">
            <div className="bg-blue-50 dark:bg-blue-900/50 p-3 rounded-lg h-full flex flex-col justify-between">
              <div>
                <div className="flex items-center space-x-2 mb-2">
                  <UserCircleIcon className="h-5 w-5 text-blue-500 dark:text-blue-400" />
                  <h4 className="text-sm font-semibold text-blue-800 dark:text-blue-200">Analysis</h4>
                </div>
                <p className="text-xs text-blue-700 dark:text-blue-300 mb-2 line-clamp-3">{summary}</p>
              </div>
              <div className={`flex items-center space-x-1 ${getRecommendationColor(recommendation)}`}>
                {getRecommendationIcons(recommendation)}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default StockItemDetailed;
