import React, { useState, useCallback, useRef, useEffect } from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { searchApi } from '../../services/api'
import { Link, useNavigate } from 'react-router-dom'

export default function SearchBar({ onSearchResults }) {
  const [searchQuery, setSearchQuery] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState({ stocks: [], news: [] })
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const navigate = useNavigate()

  const debouncedSearch = useCallback((query) => {
    let timeoutId = null;
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(async () => {
        if (query.trim()) {
          setIsLoading(true)
          try {
            const searchResults = await searchApi.search(query)
            setResults(searchResults)
            setShowDropdown(true)
            if (typeof onSearchResults === 'function') {
              onSearchResults(searchResults)
            }
          } catch (error) {
            console.error('Error searching:', error)
          } finally {
            setIsLoading(false)
          }
        } else {
          setResults({ stocks: [], news: [] })
          setShowDropdown(false)
          if (typeof onSearchResults === 'function') {
            onSearchResults({ stocks: [], news: [] })
          }
        }
      }, 300);
    };
  }, [setIsLoading, setResults, setShowDropdown, onSearchResults]);

  const handleSearchChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    debouncedSearch(query)()
  }

  const clearSearch = () => {
    setSearchQuery('')
    setResults({ stocks: [], news: [] })
    setShowDropdown(false)
    if (typeof onSearchResults === 'function') {
      onSearchResults({ stocks: [], news: [] })
    }
  }

  const handleKeyDown = (e) => {
    if (!showDropdown) return

    const totalItems = results.stocks.length + results.news.length

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault()
        setSelectedIndex((prevIndex) => (prevIndex + 1) % totalItems)
        break
      case 'ArrowUp':
        e.preventDefault()
        setSelectedIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems)
        break
      case 'Enter':
        e.preventDefault()
        if (selectedIndex !== -1) {
          const selectedItem = [...results.stocks, ...results.news][selectedIndex]
          if (selectedItem) {
            const path = selectedItem.symbol ? `/stock/${selectedItem.symbol}` : `/news/${selectedItem.id}`
            navigate(path)
            setShowDropdown(false)
          }
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    setSelectedIndex(-1)
  }, [results])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <div className={`relative flex items-center transition-all duration-300 ${isFocused ? 'ring-1 ring-blue-500' : ''}`}>
        <input
          type="text"
          placeholder="Search for stocks or news..."
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={handleKeyDown}
          className="w-full pl-8 pr-8 py-1 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none dark:bg-gray-700 dark:text-white transition-all duration-300"
        />
        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
          {isLoading ? (
            <svg className="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
        {searchQuery && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute right-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      {showDropdown && (results.stocks.length > 0 || results.news.length > 0) && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
          {results.stocks.length > 0 && (
            <div>
              <h3 className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">Stocks</h3>
              <ul>
                {results.stocks.map((stock, index) => (
                  <li key={stock.symbol}>
                    <Link
                      to={`/stock/${stock.symbol}`}
                      className={`block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${
                        index === selectedIndex ? 'bg-gray-100 dark:bg-gray-700' : ''
                      }`}
                      onClick={() => setShowDropdown(false)}
                    >
                      {stock.symbol} - {stock.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {results.news.length > 0 && (
            <div>
              <h3 className="px-4 py-2 text-sm font-semibold text-gray-700 dark:text-gray-300">News</h3>
              <ul>
                {results.news.map((newsItem, index) => (
                  <li key={newsItem.id}>
                    <Link
                      to={`/news/${newsItem.id}`}
                      className={`block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${
                        index + results.stocks.length === selectedIndex ? 'bg-gray-100 dark:bg-gray-700' : ''
                      }`}
                      onClick={() => setShowDropdown(false)}
                    >
                      {newsItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
