import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Breadcrumb from './Breadcrumb'
import { useTheme } from '../../contexts/ThemeContext'

export default function Layout({ children, searchBar, newsItem, stockItem, breadcrumb }) {
  const { isDarkMode } = useTheme();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector('.fixed.top-0');
    if (header) {
      setHeaderHeight(header.offsetHeight);
    }
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header />
        <div className="flex justify-between items-center bg-white dark:bg-gray-800 shadow-sm h-16">
          <div className="flex-1 px-4">
            {breadcrumb || <Breadcrumb newsItem={newsItem} stockItem={stockItem} />}
          </div>
          <div className="flex-1 px-4 flex justify-end">
            {searchBar && (
              <div className="w-full max-w-xl">
                {searchBar}
              </div>
            )}
          </div>
        </div>
      </div>
      <main className={`flex-grow bg-white dark:bg-gray-800`} style={{ paddingTop: `${headerHeight}px` }}>
        <div className="mx-auto px-4 py-4 max-w-full">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  )
}