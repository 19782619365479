import React from 'react'
import NewsItemDetailed from './NewsItemDetailed'
import { motion } from 'framer-motion'
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const NewsList = ({ newsData, addToWatchlist, removeFromWatchlist, watchlist, isLoading }) => {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh]">
        <ArrowPathIcon className="w-20 h-20 text-blue-500 animate-spin mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
          Loading News
        </h2>
        <p className="text-gray-500 dark:text-gray-400">
          Please wait while we fetch the latest news articles...
        </p>
      </div>
    );
  }

  return (
    <motion.div 
      className="space-y-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      {newsData.map((item, index) => (
        <motion.div
          key={item.id}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: index * 0.03 }}
        >
          <NewsItemDetailed 
            newsItem={item} 
            isInWatchlist={watchlist.news.some(news => news.id === item.id)}
            onWatchlistToggle={() => 
              watchlist.news.some(news => news.id === item.id)
                ? removeFromWatchlist(item, 'news')
                : addToWatchlist(item, 'news')
            }
          />
        </motion.div>
      ))}
    </motion.div>
  )
}

export default NewsList
