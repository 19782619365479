import React from 'react';
import StockItemDetailed from './StockItemDetailed';
import { motion } from 'framer-motion';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function StockList({ stockData, isLoading, watchlist, addToWatchlist, removeFromWatchlist }) {
  const handleWatchlistToggle = (stock) => {
    if (watchlist.stocks.some(item => item.id === stock.id)) {
      removeFromWatchlist(stock, 'stock');
    } else {
      addToWatchlist(stock, 'stock');
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh]">
        <ArrowPathIcon className="w-20 h-20 text-blue-500 animate-spin mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
          Loading Stocks
        </h2>
        <p className="text-gray-500 dark:text-gray-400">
          Please wait while we fetch the latest stock data...
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {stockData.map((stock, index) => (
        <motion.div
          key={stock.id}
          className="h-full"
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: index * 0.03 }}
        >
          <StockItemDetailed
            stock={stock}
            isInWatchlist={watchlist.stocks.some(item => item.id === stock.id)}
            onWatchlistToggle={handleWatchlistToggle}
          />
        </motion.div>
      ))}
    </div>
  );
}
