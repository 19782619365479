export const mockStocks = [
    {
        id: 'AAPL',
        symbol: 'AAPL',
        name: 'Apple Inc.',
        price: 150.25,
        change: 2.5,
        recommendation: -1,
        summary: 'Strong product lineup and services growth. Potential in AR/VR space.',
        description: 'Apple Inc. designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories worldwide.',
        sector: 'Technology',
        marketCap: 2450000000000,
        peRatio: 28.5,
        dividendYield: 0.0058,
        high52Week: 182.94,
        low52Week: 124.17,
        volume: 75840000,
        recentNews: [
            { title: 'Apple Announces New MacBook Pro with M2 Chip', date: '2023-06-05T09:00:00Z' },
            { title: 'Apple\'s AR Headset: What We Know So Far', date: '2023-06-03T14:30:00Z' },
            { title: 'Apple Services Revenue Hits New All-Time High', date: '2023-06-01T11:15:00Z' }
        ],
        financialData: {
            revenue: 394328000000,
            netIncome: 99803000000,
            eps: 6.11,
            debtToEquity: 1.76,
            freeCashFlow: 111443000000,
            profitMargin: 0.2531
        },
        analysts: [
            { name: 'Sarah Johnson', role: 'Senior Analyst', specialty: 'Tech Sector Specialist' },
            { name: 'Michael Chen', role: 'Market Strategist', specialty: 'Consumer Electronics' },
            { name: 'Emily Rodriguez', role: 'Financial Analyst', specialty: 'Growth Stocks' }
        ]
    },
    {
        id: 'MSFT',
        symbol: 'MSFT',
        name: 'Microsoft Corporation',
        price: 335.40,
        change: 1.8,
        recommendation: 1,
        summary: 'Strong cloud growth with Azure. Expanding AI capabilities.',
        description: 'Microsoft Corporation develops, licenses, and supports software, services, devices, and solutions worldwide.',
        sector: 'Technology',
        marketCap: 2500000000000,
        peRatio: 32.1,
        dividendYield: 0.0087,
        high52Week: 349.67,
        low52Week: 213.43,
        volume: 21500000,
        recentNews: [
            { title: 'Microsoft Unveils New AI-Powered Bing Features', date: '2023-06-04T10:30:00Z' },
            { title: 'Azure Revenue Surpasses Expectations in Q2', date: '2023-06-02T15:45:00Z' },
            { title: 'Microsoft Teams Introduces Advanced Collaboration Tools', date: '2023-05-31T13:20:00Z' }
        ],
        financialData: {
            revenue: 198270000000,
            netIncome: 72738000000,
            eps: 9.65,
            debtToEquity: 0.46,
            freeCashFlow: 63331000000,
            profitMargin: 0.3669
        },
        analysts: [
            { name: 'David Thompson', role: 'Senior Analyst', specialty: 'Cloud Computing' },
            { name: 'Lisa Wang', role: 'Technology Analyst', specialty: 'Enterprise Software' },
            { name: 'Robert Martinez', role: 'Market Strategist', specialty: 'AI and Machine Learning' }
        ]
    },
    {
        id: 'GOOGL',
        symbol: 'GOOGL',
        name: 'Alphabet Inc.',
        price: 124.67,
        change: -0.5,
        recommendation: 0,
        summary: 'Dominant in search and online advertising. Growing cloud business.',
        description: 'Alphabet Inc. provides various products and platforms in the areas of search, ads, commerce, and cloud.',
        sector: 'Technology',
        marketCap: 1580000000000,
        peRatio: 27.8,
        dividendYield: null,
        high52Week: 129.55,
        low52Week: 83.34,
        volume: 28700000,
        recentNews: [
            { title: 'Google I/O 2023: New AI Features Announced', date: '2023-06-05T11:00:00Z' },
            { title: 'YouTube Shorts Surpasses TikTok in Active Users', date: '2023-06-03T16:15:00Z' },
            { title: 'Google Cloud Expands Partnership with SAP', date: '2023-06-01T09:45:00Z' }
        ],
        financialData: {
            revenue: 282836000000,
            netIncome: 59972000000,
            eps: 4.56,
            debtToEquity: 0.07,
            freeCashFlow: 60010000000,
            profitMargin: 0.2120
        },
        analysts: [
            { name: 'Jennifer Lee', role: 'Senior Analyst', specialty: 'Digital Advertising' },
            { name: 'Mark Wilson', role: 'Technology Analyst', specialty: 'Search Engines' },
            { name: 'Sophia Patel', role: 'Market Strategist', specialty: 'AI and Machine Learning' }
        ]
    },
    {
        id: 'AMZN',
        symbol: 'AMZN',
        name: 'Amazon.com, Inc.',
        price: 129.33,
        change: 1.2,
        recommendation: 1,
        summary: 'E-commerce giant with strong cloud computing business.',
        description: 'Amazon.com, Inc. engages in the retail sale of consumer products and subscriptions in North America and internationally.',
        sector: 'Consumer Cyclical',
        marketCap: 1320000000000,
        peRatio: 100.8,
        dividendYield: null,
        high52Week: 146.57,
        low52Week: 81.43,
        volume: 50200000,
        recentNews: [
            { title: 'Amazon Expands Same-Day Delivery to More Cities', date: '2023-06-07T14:30:00Z' },
            { title: 'AWS Launches New AI Service for Developers', date: '2023-06-05T10:45:00Z' },
            { title: 'Amazon Prime Day Dates Announced', date: '2023-06-02T09:00:00Z' }
        ],
        financialData: {
            revenue: 513983000000,
            netIncome: 11323000000,
            eps: 1.08,
            debtToEquity: 0.89,
            freeCashFlow: 11573000000,
            profitMargin: 0.0220
        },
        analysts: [
            { name: 'Sarah Johnson', role: 'Senior Analyst', specialty: 'E-commerce' },
            { name: 'Michael Chen', role: 'Technology Analyst', specialty: 'Cloud Computing' },
            { name: 'Emily Brown', role: 'Market Strategist', specialty: 'Retail Sector' }
        ]
    },
    {
        id: 'TSLA',
        symbol: 'TSLA',
        name: 'Tesla, Inc.',
        price: 249.83,
        change: -2.1,
        recommendation: 0,
        summary: 'Leading electric vehicle manufacturer with growing energy business.',
        description: 'Tesla, Inc. designs, develops, manufactures, leases, and sells electric vehicles, and energy generation and storage systems.',
        sector: 'Consumer Cyclical',
        marketCap: 792000000000,
        peRatio: 73.5,
        dividendYield: null,
        high52Week: 314.67,
        low52Week: 101.81,
        volume: 123000000,
        recentNews: [
            { title: 'Tesla Unveils New Supercharger Station Design', date: '2023-06-08T11:15:00Z' },
            { title: 'Elon Musk Hints at New Tesla Model', date: '2023-06-06T16:30:00Z' },
            { title: 'Tesla\'s Self-Driving Software Gets Major Update', date: '2023-06-04T09:45:00Z' }
        ],
        financialData: {
            revenue: 81462000000,
            netIncome: 12583000000,
            eps: 3.62,
            debtToEquity: 0.08,
            freeCashFlow: 7577000000,
            profitMargin: 0.1545
        },
        analysts: [
            { name: 'Alex Turner', role: 'Senior Analyst', specialty: 'Electric Vehicles' },
            { name: 'Rachel Wong', role: 'Technology Analyst', specialty: 'Autonomous Driving' },
            { name: 'Daniel Garcia', role: 'Market Strategist', specialty: 'Renewable Energy' }
        ]
    }
];