import React, { createContext, useState, useContext, useEffect } from 'react';

const WatchlistContext = createContext();

export const WatchlistProvider = ({ children }) => {
  const [watchlist, setWatchlist] = useState(() => {
    // Initialize watchlist from localStorage
    const savedWatchlist = localStorage.getItem('watchlist');
    return savedWatchlist ? JSON.parse(savedWatchlist) : { stocks: [], news: [] };
  });
  const [lastUpdatedType, setLastUpdatedType] = useState(null);

  // Update localStorage whenever watchlist changes
  useEffect(() => {
    localStorage.setItem('watchlist', JSON.stringify(watchlist));
  }, [watchlist]);

  const addToWatchlist = (item, type) => {
    setWatchlist(prevWatchlist => {
      if (type === 'stock' && !prevWatchlist.stocks.some(stock => stock.id === item.id)) {
        setLastUpdatedType('stock');
        return { ...prevWatchlist, stocks: [...prevWatchlist.stocks, item] };
      } else if (type === 'news' && !prevWatchlist.news.some(news => news.id === item.id)) {
        setLastUpdatedType('news');
        return { ...prevWatchlist, news: [...prevWatchlist.news, item] };
      }
      return prevWatchlist;
    });
  };

  const removeFromWatchlist = (item, type) => {
    setWatchlist(prevWatchlist => {
      if (type === 'stock') {
        setLastUpdatedType('stock');
        return { ...prevWatchlist, stocks: prevWatchlist.stocks.filter(stock => stock.id !== item.id) };
      } else if (type === 'news') {
        setLastUpdatedType('news');
        return { ...prevWatchlist, news: prevWatchlist.news.filter(news => news.id !== item.id) };
      }
      return prevWatchlist;
    });
  };

  return (
    <WatchlistContext.Provider value={{ watchlist, lastUpdatedType, addToWatchlist, removeFromWatchlist }}>
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlist = () => useContext(WatchlistContext);
