import React from 'react'
import { Link } from 'react-router-dom'
import { StarIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'

const StockItemCompact = ({ stock, onRemove }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.15, ease: "easeInOut" }}
  >
    <Link to={`/stock/${stock.id}`} className="block">
      <div className="flex items-center justify-between p-3 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow transition-shadow duration-200">
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden flex items-center justify-center">
            <img
              src={`https://via.placeholder.com/40.png?text=${stock.symbol}`}
              alt={`${stock.name} logo`}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h3 className="font-medium text-gray-900 dark:text-gray-100">{stock.symbol}</h3>
            <span className={`text-sm ${stock.change >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
              ${stock.price.toFixed(2)} ({stock.change >= 0 ? '+' : ''}{stock.change}%)
            </span>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove(stock);
          }}
          className="text-yellow-500 hover:text-yellow-600 dark:text-yellow-400 dark:hover:text-yellow-300 transition-colors duration-200"
        >
          <StarIcon className="h-5 w-5 fill-current" />
        </motion.button>
      </div>
    </Link>
  </motion.div>
)

export default StockItemCompact;