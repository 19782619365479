import { useState, useEffect, useCallback } from 'react'

export const useHomePageLogic = (stocksApi, newsApi) => {
  const [activeTab, setActiveTab] = useState('breaking-news')
  const [stocks, setStocks] = useState([])
  const [news, setNews] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [stocksData, newsData] = await Promise.all([
          stocksApi.getStocks(),
          newsApi.getNews()
        ]);
        setStocks(stocksData);
        setNews(newsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [stocksApi, newsApi]);

  const handleSearch = useCallback((query) => {
    console.log('Searching for:', query)
    // Implement search functionality here
  }, [])

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab)
  }, [])

  return {
    activeTab,
    stocks,
    news,
    isLoading,
    handleTabChange,
    handleSearch,
    setHomePageTab: handleTabChange,
  }
}
