import React from 'react'
import { Link } from 'react-router-dom'
import { StarIcon } from '@heroicons/react/24/outline'
import { CalendarIcon, NewspaperIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'

const NewsItemCompact = ({ newsItem, onRemove }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    transition={{ duration: 0.15, ease: "easeInOut" }}
  >
    <Link to={`/news/${newsItem.id}`} className="block">
      <div className="flex items-center justify-between p-3 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow transition-shadow duration-200">
        <div className="flex-grow mr-3">
          <h3 className="font-medium text-gray-900 dark:text-gray-100 text-sm leading-snug mb-1">{newsItem.title}</h3>
          <div className="flex items-center text-xs text-gray-500 dark:text-gray-400 space-x-2">
            <div className="flex items-center">
              <NewspaperIcon className="h-3 w-3 mr-1" />
              <span>{newsItem.category}</span>
            </div>
            <div className="flex items-center">
              <CalendarIcon className="h-3 w-3 mr-1" />
              <span>{new Date(newsItem.date).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove(newsItem);
          }}
          className="text-yellow-500 hover:text-yellow-600 dark:text-yellow-400 dark:hover:text-yellow-300 transition-colors duration-200 flex-shrink-0"
        >
          <StarIcon className="h-5 w-5 fill-current" />
        </motion.button>
      </div>
    </Link>
  </motion.div>
)

export default NewsItemCompact;